import {
	mdiAccountDetailsOutline,
	mdiCashCheck,
	mdiPercent,
	mdiHumanCapacityDecrease,
	mdiHome,
	mdiHumanMaleFemale, mdiCurrencyUsd, mdiFilterVariant,
} from '@mdi/js'

export default [
	{
		title: 'Asosiy panel',
		icon: mdiHome,
		to: 'dashboard',
		resource: 'Public',
		action: 'read',
	},
  {
    title: 'Guruhlar',
    to: 'groups',
    icon: mdiPercent,
    resource: 'Group',
    action: 'read',
  },
  {
    title: 'Talabalar',
    icon: mdiAccountDetailsOutline,
    to: 'students',
    resource: 'Student',
    action: 'read',
  },
  {
		title: 'Guruh talabalari',
		to: 'student-groups',
		icon: mdiHumanCapacityDecrease,
		resource: 'StudentGroup',
		action: 'read',
	},
  {
    subheader: '#',
    badge: 'New',
    bageColor: 'success',
    resource: 'Public',
    action: 'read',
  },
  {
    title: "Ustozlar ro'yxati",
    to: 'teachers',
    resource: 'Teacher',
    action: 'read',
    icon: mdiHumanMaleFemale,
  },
	{
		title: 'Guruh vaqtlari',
		icon: mdiAccountDetailsOutline,
		to: 'group-times',
		resource: 'GroupTime',
		action: 'read',
	},
  {
    title: 'Lidlar',
    icon: mdiAccountDetailsOutline,
    to: 'leads',
    resource: 'Lead',
    action: 'read',
  },
  {
    subheader: '##',
    badge: 'New',
    bageColor: 'success',
    resource: 'Public',
    action: 'read',
  },
	{
		title: "Talabadan to\'lovlar",
		icon: mdiCashCheck,
		to: 'student-paids',
		resource: 'StudentPaid',
		action: 'read',
	},
  {
    title: 'Qarzdor talabalar',
    icon: mdiAccountDetailsOutline,
    to: 'student-debts',
    resource: 'StudentDebt',
    action: 'read',
  },
	{
		title: "To'lovlar",
		icon: mdiAccountDetailsOutline,
		to: 'payments',
		resource: 'Public',
		action: 'read',
	},
	{
		subheader: 'Kassa',
		badge: 'New',
		bageColor: 'success',
		resource: 'Public',
		action: 'read',
	},
	{
		title: 'Xarajatlar',
		to: 'expenses',
		icon: mdiCurrencyUsd,
		resource: 'Expense',
		action: 'read',
	},
	{
		title: 'Kirimlar',
		to: 'incomes',
		icon: mdiCurrencyUsd,
		resource: 'Income',
		action: 'read',
	},
	{
		title: 'Kassalar',
		to: 'cashboxes',
		icon: mdiCurrencyUsd,
		resource: 'Cashbox',
		action: 'read',
	},
	{
		title: 'Hisobot',
		to: 'report-cash',
		icon: mdiCurrencyUsd,
		resource: 'Cashbox',
		action: 'read',
	},
]
