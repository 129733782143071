import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VMenu,{attrs:{"offset-y":"","nudge-bottom":"22","min-width":"175","left":"","elevation":_vm.$vuetify.theme.dark ? 9 : 8}},[_c(VList,[_c(VListItemGroup,{attrs:{"value":_vm.$i18n.locale},on:{"change":_vm.updateActiveLocale}},_vm._l((_vm.locales),function(locale){return _c(VListItem,{key:locale.locale,attrs:{"value":locale.locale}},[_c(VImg,{staticClass:"me-2",attrs:{"src":locale.img,"height":"14px","width":"22px","alt":locale.locale}}),_c(VListItemTitle,[_vm._v(_vm._s(locale.title))])],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }