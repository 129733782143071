const BASE_URL= location.protocol + '//mtb.softwork.uz'
const BACKEND_URL= location.protocol + '//mtb.softwork.uz'

// const BASE_URL = location.protocol + '//app.softwork.uz'
// const BACKEND_URL = location.protocol + '//mm.softwork.uz'

const envParams = {
	BASE_URL: BASE_URL,
	BACKEND_URL: BACKEND_URL,
	API_URL: BACKEND_URL + '/api/',
	IS_LOCAL: true,
	COMPANY_NAME: 'MUTAALLIM',
}

export default envParams
