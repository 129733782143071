import store from '@/store'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    shallContentShowOverlay: false,
    branch: {},
    user: { role: null },
  },
  getters: {},
  mutations: {
    TOGGLE_CONTENT_OVERLAY(state, value) {
      state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
    },

    SET_BRANCH(state, value) {
      state.branch = value
      if (value.id) {
        store.commit('SET_BRANCH_ID', value.id)
        store.commit('SET_BRANCH_NAME', value.name)
      } else {
        store.commit('SET_BRANCH_ID', null)
        store.commit('SET_BRANCH_NAME', null)
      }
    },


    SET_USER_ROLE(state, value) {
      if (value) {
        state.user.role = value
        store.commit('SET_USER_ROLE', value)
      } else {
        state.user.role = null
        store.commit('SET_USER_ROLE', null)
      }
    },
  },
  actions: {
    changeBranch({ commit }, branchId) {

      let branch_id
      if (typeof branchId === 'string')
        branch_id = parseInt(branchId)
      else if (branchId.id) {
        branch_id = branchId.id
      }

      axios.get(`api/branches/${branch_id}`).then(response => {
        const branch = response.data.data

        commit('SET_BRANCH', branch)
        localStorage.setItem('branch', JSON.stringify(branch))
      })

    },
    setBranch({ commit, dispatch }, branchId) {
      let branch_id
      if (typeof branchId === 'string' || typeof branchId === 'number')
        branch_id = parseInt(branchId)
      else if (branchId.id) {
        branch_id = branchId.id
      } else {
        return
      }

      commit('SET_BRANCH', {
        id: branch_id,
        name: 'Loading...',
      })

      axios.get(`api/branches/${branch_id}`).then(response => {
        const branch = response.data.data
        commit('SET_BRANCH', branch)
        localStorage.setItem('branch', JSON.stringify(branch))
      })

    },
    setUserRole({ commit }, role) {
      commit('SET_USER_ROLE', role)
    },
  },
}
